import React, { useMemo } from 'react';
import ChatHistoryDayEntry from './ChatHistoryDayEntry';
import { useTranslation } from 'react-i18next';
import { i18n, TFunction } from 'i18next';
import { useAppSelector } from '../redux/useAppSelector';
import { ChatState } from '../redux/states/conversations/ChatState';

type Props = {
	date: string;
	chatSessions: ChatState[];
	showDivider: boolean;
}

const dateDiffInDays = (a: Date, b: Date) => {
	const MS_PER_DAY = 1000 * 60 * 60 * 24;
	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

const dateStringToDisplayName = (dateString: string, i18n: i18n, t: TFunction) => {
	const dateObject = new Date(dateString);
	const diffDays = dateDiffInDays(dateObject, new Date());
	if (diffDays === 0) {
		return t('chatPage.history.today');
	} else if (diffDays === 1) {
		return t('chatPage.history.yesterday');
	}
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	return dateObject.toLocaleDateString(i18n.language === 'nl' ? 'nl-BE' : i18n.language === 'fr' ? 'fr-BE' : i18n.language, options);
};

const ChatHistoryDay: React.FC<Props> = ({ chatSessions, date, showDivider }) => {
	const { i18n, t } = useTranslation();
	const dateDisplayName = useMemo(() => dateStringToDisplayName(date, i18n, t), [date, i18n, t]);
	const { selectedId } = useAppSelector(state => state.conversations);

	return (
		<div className={'flex flex-col'}>
			<p className={'mb-2 text-sm'}>{dateDisplayName}</p>
			<div className={'flex flex-col space-y-2'}>
				{chatSessions.map(session => <ChatHistoryDayEntry
					isActive={selectedId === session.id}
					chatSession={session}
					key={session.id} />,
				)}
			</div>
			{showDivider && <div className={'flex h-[2px] opacity-20 rounded mb-4 mt-4 bg-black  w-full'} />}
		</div>
	);
};

export default ChatHistoryDay;