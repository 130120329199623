import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import { Checkbox, Dropdown, Input, Label, makeStyles, Option, OptionOnSelectData, SelectionEvents, Text, Textarea, tokens, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components';
import { CalendarStrings, DatePicker } from '@fluentui/react-datepicker-compat';
import { useTranslation } from 'react-i18next';
import { DayOfWeek } from '@fluentui/react-calendar-compat';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';

type Props = {
	section: string;
	option: OptionTemplateConfiguration;
	setRequestData: React.Dispatch<React.SetStateAction<GenerateDocumentDataInterface>>;
	selectedDocumentId?: string;
}

const useClasses = makeStyles({
	column: {
		flexDirection: 'column',
		gap: '2px',
		marginBottom: tokens.spacingVerticalM,
		display: 'flex',
	},
});

const DocumentGenerationOptionCheckbox: React.FC<Props> = ({ selectedDocumentId, option, section, setRequestData }) => {
	const toOptionSectionId = useCallback((id: string) => `${section}.${id}`, [section]);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);	

	useEffect(() => {
		if (!option.isPreselected) return;
		setRequestData((prev) => {
			const copy = { ...prev };
			if (!copy[section]) {
				copy[section] = {};
			}
			copy[section][option.id] = true;
			return copy;
		});
		setSelectedIds(prev => {
			if (prev.includes(option.id)) return prev;
			return [...prev, option.id];
		});
		// eslint-disable-next-line
	}, [option, selectedDocumentId, section]);

	const updateDataInternal = useCallback((ids: string[], enable: boolean) => {
		setRequestData((prev) => {
			const copy = { ...prev };
			if (enable) {
				if (!copy[section]) {
					copy[section] = {};
				}
				ids.forEach((id, index) => copy[section][id] = index === ids.length - 1);
			} else {
				if (!copy[section]) return copy;
				ids.forEach(id => delete copy[section][id]);
				if (Object.entries(copy[section]).length === 0) delete copy[section];
			}
			return copy;
		});
	}, [setRequestData, section]);

	const toggleSelectedIdsWithParent = useCallback((parents: OptionTemplateConfiguration[], selectedIds: string[]): string[] => {
		const newList = parents.reduceRight((acc, currentValue, index) => {
			if (acc.findIndex(selectedId => currentValue.children.map(child => child.id).includes(selectedId)) === -1) {
				return acc.filter(c => c !== currentValue.id);
			} else if (!acc.includes(currentValue.id)) {
				return [...acc, currentValue.id];
			}
			return acc;
		}, selectedIds);
		if (selectedIds.length > newList.length) {
			return selectedIds.filter(id => !newList.includes(id));
		} else {
			return newList.filter(id => !selectedIds.includes(id));
		}
	}, []);

	const toggleSelected = useCallback((enable: boolean, option: OptionTemplateConfiguration, parents: OptionTemplateConfiguration[]) => {
		if (enable) {
			if (selectedIds.includes(option.id)) return;
			const newSelectedIds = [...selectedIds, option.id];
			const parentIdsToToggle = toggleSelectedIdsWithParent(parents, [...selectedIds, option.id]);
			setSelectedIds([...newSelectedIds, ...parentIdsToToggle]);
			updateDataInternal([...parentIdsToToggle, option.id], true);
		} else {
			if (!selectedIds.includes(option.id)) return;
			const newSelectedIds = selectedIds.filter(c => c !== option.id);
			const parentIdsToToggle = toggleSelectedIdsWithParent(parents, newSelectedIds);
			setSelectedIds(selectedIds.filter(id => !parentIdsToToggle.includes(id) && id !== option.id));
			updateDataInternal([...parentIdsToToggle, option.id], false);
		}
	}, [updateDataInternal, selectedIds, toggleSelectedIdsWithParent]);

	const renderOptions = (options: OptionTemplateConfiguration[], parents: OptionTemplateConfiguration[]) => {
		if (options.length === 0) return null;
		return (
			<Tree aria-label={toOptionSectionId(option.id)}>
				{options.map(option => {
					const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
						toggleSelected(event.target.checked, option, parents);
					};
					return (
						<TreeItem itemType={option.children.length === 0 ? 'leaf' : 'branch'} key={option.id}>
							<TreeItemLayout>
								{option.children.length === 0 ? (
									<Checkbox label={option.name} checked={selectedIds.includes(option.id)} onChange={onChange} />
								) : (
									<Text>{option.name}</Text>
								)}
							</TreeItemLayout>
							{renderOptions(option.children, [...parents, option])}
						</TreeItem>
					);
				})}
			</Tree>
		);
	};

	return option.children.length > 0 ?
		renderOptions([option], []) :
		<Checkbox label={option.name} checked={selectedIds.includes(option.id)} onChange={ev => toggleSelected(ev.target.checked, option, [])} />;
};

const DocumentGenerationOptionText: React.FC<Props> = ({ option, section, setRequestData }) => {
	const [content, setContent] = useState<string>('');
	const classes = useClasses();

	useEffect(() => {
		setRequestData((prev) => {
			const copy = { ...prev };
			if (!copy[section]) {
				copy[section] = {};
			}
			copy[section][option.id] = content;
			return copy;
		});
	}, [content, setRequestData, option, section]);

	const updateContentTextInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setContent(event.target.value);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Input value={content} onChange={updateContentTextInput} placeholder={option.placeholder} />
		</div>
	);
};

const DocumentGenerationOptionDate: React.FC<Props> = ({ option, section, setRequestData }) => {
	const [content, setContent] = useState<Date>(new Date());
	const classes = useClasses();
	const { t } = useTranslation();
	const calendarStrings = useMemo<CalendarStrings>(() => ({
		days: [
			t('datepicker.days.sunday'),
			t('datepicker.days.monday'),
			t('datepicker.days.tuesday'),
			t('datepicker.days.wednesday'),
			t('datepicker.days.thursday'),
			t('datepicker.days.friday'),
			t('datepicker.days.saturday'),
		], shortDays: [
			t('datepicker.shortDays.sunday'),
			t('datepicker.shortDays.monday'),
			t('datepicker.shortDays.tuesday'),
			t('datepicker.shortDays.wednesday'),
			t('datepicker.shortDays.thursday'),
			t('datepicker.shortDays.friday'),
			t('datepicker.shortDays.saturday'),
		],
		months: [
			t('datepicker.months.january'),
			t('datepicker.months.february'),
			t('datepicker.months.march'),
			t('datepicker.months.april'),
			t('datepicker.months.may'),
			t('datepicker.months.june'),
			t('datepicker.months.july'),
			t('datepicker.months.august'),
			t('datepicker.months.september'),
			t('datepicker.months.october'),
			t('datepicker.months.november'),
			t('datepicker.months.december'),
		],
		shortMonths: [
			t('datepicker.shortMonths.january'),
			t('datepicker.shortMonths.february'),
			t('datepicker.shortMonths.march'),
			t('datepicker.shortMonths.april'),
			t('datepicker.shortMonths.may'),
			t('datepicker.shortMonths.june'),
			t('datepicker.shortMonths.july'),
			t('datepicker.shortMonths.august'),
			t('datepicker.shortMonths.september'),
			t('datepicker.shortMonths.october'),
			t('datepicker.shortMonths.november'),
			t('datepicker.shortMonths.december'),
		],
		goToToday: t('datepicker.goToToday'),
	}), [t]);

	const onFormatDate = useCallback((date?: Date) => !date ? '' : `${date.getDate()} ${calendarStrings.months[date.getMonth()]} ${date.getFullYear()}`, [calendarStrings]);

	useEffect(() => {
		setRequestData((prev) => {
			const copy = { ...prev };
			if (!copy[section]) {
				copy[section] = {};
			}
			copy[section][option.id] = content;
			return copy;
		});
	}, [content, setRequestData, option, section]);

	const updateDateInput = (date: Date | null | undefined) => {
		if (date) {
			setContent(date);
		}
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<DatePicker value={new Date(content)} strings={calendarStrings} formatDate={onFormatDate} onSelectDate={updateDateInput} firstDayOfWeek={DayOfWeek.Monday} maxDate={new Date()} />
		</div>
	);
};

const DocumentGenerationOptionTextArea: React.FC<Props> = ({ option, section, setRequestData }) => {
	const [content, setContent] = useState<string>('');
	const classes = useClasses();

	useEffect(() => {
		setRequestData(prev => {
			const copy = { ...prev };
			if (!copy[section]) {
				copy[section] = {};
			}
			copy[section][option.id] = content;
			return copy;
		});
	}, [content, setRequestData, option, section]);

	const updateContentTextArea: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
		setContent(event.target.value);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Textarea value={content} onChange={updateContentTextArea} placeholder={option.placeholder} />
		</div>
	);
};


const DocumentGenerationOptionDropdown: React.FC<Props> = ({ option, section, setRequestData }) => {
	const classes = useClasses();
	const [selectedOptionIndex, setSelectedIndexOption] = useState<number>(0);

	useEffect(() => {
		setRequestData((prev) => {
			const copy = { ...prev };
			if (!copy[section]) {
				copy[section] = {};
			}
			copy[section][option.id] = option.values[selectedOptionIndex];
			return copy;
		});
	}, [setRequestData, option, section, selectedOptionIndex]);

	const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		const index = option.values.findIndex(value => value === data.optionValue);
		setSelectedIndexOption(index);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Dropdown defaultValue={option.values[0]} onOptionSelect={onOptionSelect}>
				{option.values.map(value => (
					<Option key={value}>
						{value}
					</Option>
				))}
			</Dropdown>
		</div>
	);
};

const DocumentGenerationOption: React.FC<Props> = (props) => {
	switch (props.option.type) {
		case 'Checkbox':
			return <DocumentGenerationOptionCheckbox {...props} />;
		case 'TextArea':
			return <DocumentGenerationOptionTextArea {...props} />;
		case 'Text':
			return <DocumentGenerationOptionText {...props} />;
		case 'Date':
			return <DocumentGenerationOptionDate {...props} />;
		case 'Dropdown':
			return <DocumentGenerationOptionDropdown {...props} />;
	}
};

export default DocumentGenerationOption;