import React, { ChangeEvent, Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Checkbox,
	CheckboxOnChangeData,
	Combobox,
	InteractionTag,
	InteractionTagPrimary,
	InteractionTagSecondary,
	Label,
	makeStyles,
	OptionOnSelectData,
	Popover,
	PopoverSurface,
	PopoverTrigger,
	PositioningShorthand,
	Select,
	SelectionEvents,
	Spinner,
	TagDismissHandler,
	TagGroup,
	Text,
	Toast,
	ToastBody,
	Toaster,
	ToastTitle,
	tokens,
	useComboboxFilter,
	useId,
	useToastController,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import useMap from '../hooks/useMap';
import useGetMapLayers from '../hooks/queries/useGetMapLayers';
import useGetGeoAddresses from '../hooks/queries/useGetGeoAddress';
import AutofillSearchBar from '../components/AutofillSearchBar';
import NameAndId from '../interfaces/NameAndId';
import { GeoLocationResponseInterfaceEntry } from '../interfaces/response/GeoLocationResponseInterface';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { api } from '../index';
import Region from '../interfaces/Region';

type Props = {
	region: Region
}

const useClasses = makeStyles({
	root: {
		marginTop: tokens.spacingVerticalXXXL,
		marginBottom: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
	},
	searchBarTop: {
		flexDirection: 'row',
		display: 'flex',
		marginBottom: tokens.spacingVerticalXL,
	},
	searchBarLayers: {
		flexDirection: 'column',
		display: 'flex',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	mapContainer: {
		display: 'flex',
		flex: 1,
		backgroundColor: tokens.colorBrandBackground2,
		borderRadius: '4px',
	},
	rightColumn: {
		width: '20em',
		display: 'flex',
		flexDirection: 'column',
		marginLeft: tokens.spacingHorizontalXL,
		gap: tokens.spacingVerticalM,
		maxHeight: '100%',
	},
	leftColumn: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	scaleSelectionContainer: {
		marginTop: tokens.spacingVerticalS,
		marginBottom: tokens.spacingVerticalS,
	},
	selectedLayersContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: tokens.spacingVerticalS,
	},
	layerOption: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: tokens.spacingHorizontalSNudge,
	},
	tag: {
		whiteSpace: 'normal',
		flex: 1,
		justifyContent: 'flex-start',
		paddingTop: tokens.spacingVerticalSNudge,
		paddingBottom: tokens.spacingVerticalSNudge,
		flexShrink: 0,
	},
	tagContainer: {
		width: 'auto',
		display: 'flex',
		height: 'auto',
		minHeight: '2.5em',
		justifyContent: 'space-between',
		flexShrink: 0,
	},
	popover: {
		width: 'fit-content',
		maxWidth: '100%',
		gap: tokens.spacingHorizontalS,
		height: 'fit-content',
		flex: 1,
		display: 'flex',
	},
	downloadButtonContainer: {
		flexDirection: 'column',
		display: 'flex',
	},
	backgroundPicker: {
		width: '20em',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'end',
		marginLeft: tokens.spacingHorizontalXL,
	},
	flex1: {
		flex: 1,
		display: 'flex',
	},
});

const scales = [
	{
		scale: '10',
		zoomLevel: 13,
	},
	{
		scale: '20',
		zoomLevel: 12,
	},
	{
		scale: '50',
		zoomLevel: 11,
	},
	{
		scale: '100',
		zoomLevel: 10,
	},
	{
		scale: '200',
		zoomLevel: 9,
	},
	{
		scale: '500',
		zoomLevel: 8,
	},
	{
		scale: '1000',
		zoomLevel: 7,
	},
	{
		scale: '2000',
		zoomLevel: 6,
	},
];

const defaultFlandersBackground = 'basiskaart_grb_volledige_kaart';
const defaultWalloniaBackground = 'imagerie_ortho_last_layers_0';

const updateTextWrapping: Ref<HTMLSpanElement> = (data) => {
	if (!data?.children) return;
	for (let i = 0; i < data.children.length; i++) {
		data.children[i].setAttribute('style', 'white-space: normal;');
	}
};

const MapsPage: React.FC<Props> = ({ region }) => {
	const errorToastId = useId();
	const { dispatchToast } = useToastController(errorToastId);
	const classes = useClasses();
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const { addresses } = useGetGeoAddresses(searchTerm, region);
	const mapRef = useRef<HTMLDivElement | null>(null);
	const [layerListBoundaryRef, setLayerListBoundaryRef] = useState<HTMLDivElement | null>(null);
	const { maps } = useGetMapLayers(region);
	const [layerQuery, setLayerQuery] = useState<string>('');
	const [backgroundLayerId, setBackgroundLayerId] = useState<string>(region === 'Flanders' ? defaultFlandersBackground : defaultWalloniaBackground);
	const [selectedLayers, setSelectedLayers] = useState<string[]>([]);
	const [selectedAddressEntry, setSelectedAddressEntry] = useState<NameAndId>();
	const [selectedScales, setSelectedScales] = useState<number[]>([]);
	const [isDownloadingFiles, setIsDownloadingFiles] = useState(false);
	const [mergeLayers, setMergeLayers] = useState(false);
	const map = useMap(region, backgroundLayerId, mapRef.current, maps, selectedLayers);

	useEffect(() => {
		setSelectedLayers([]);
		setSelectedAddressEntry(undefined);
		setSelectedScales([]);
		setSearchTerm('');
		setBackgroundLayerId(region === 'Flanders' ? defaultFlandersBackground : defaultWalloniaBackground);
	}, [region]);

	useEffect(() => {
		if (!selectedAddressEntry) return;
		const isMatchingId = (entry: GeoLocationResponseInterfaceEntry): boolean => entry.id === selectedAddressEntry.id;
		const selectedAddress = addresses?.addressEntries.find(isMatchingId) ?? addresses?.projectEntries.find(isMatchingId);
		if (!selectedAddress) return;
		const point = new Point([selectedAddress.coords.lambertX, selectedAddress.coords.lambertY]);
		const feature = new Feature(point);
		feature.setStyle(new Style({
			image: new CircleStyle({
				radius: 8,
				fill: new Fill({ color: '#FFd700' }),
				stroke: new Stroke({
					color: 'black',
					width: 4,
				}),
			}),
		}));
		const layerToAdd = new VectorLayer({
			source: new VectorSource({
				features: [feature],
			}),
			zIndex: 10000,
		});
		map.addLayer(layerToAdd);
		map.getView().setCenter(point.getCoordinates());
		map.getView().setZoom(10);

		return () => {
			map.removeLayer(layerToAdd);
		};
	}, [selectedAddressEntry, map, addresses]);

	const addressOptions = useMemo<NameAndId[]>(() => {
		if (!addresses) return [];
		if (addresses.addressEntries.length > addresses.projectEntries.length) {
			return addresses?.addressEntries.map(c => ({ id: c.id, name: c.description }));
		}
		return addresses?.projectEntries.map(c => ({ id: c.id, name: c.description }));
	}, [addresses]);

	const tagElements = useMemo(() => {
		const moveToIndex = (newIndex: number, layerId: string) => {
			const currentIndex = selectedLayers.indexOf(layerId);
			const newList = [...selectedLayers];
			newList.splice(currentIndex, 1);
			newList.splice(newIndex, 0, layerId);
			setSelectedLayers(newList);
		};

		return selectedLayers.map((layerId, index) => {
			const mapDefinition = maps.find(c => c.id === layerId)!;
			if (!mapDefinition) return null;
			return (
				<InteractionTag value={layerId} key={layerId} className={classes.tagContainer}>
					<Popover trapFocus>
						<PopoverTrigger>
							<InteractionTagPrimary primaryText={mapDefinition.laag} className={classes.tag} ref={updateTextWrapping} secondaryText={mapDefinition.titelService} />
						</PopoverTrigger>
						<PopoverSurface className={classes.popover}>
							<Button disabled={index === 0} onClick={() => moveToIndex(index - 1, layerId)}>{t('mapsPage.moveBack')}</Button>
							<Button disabled={index === selectedLayers.length - 1} onClick={() => moveToIndex(index + 1, layerId)}>{t('mapsPage.moveForwards')}</Button>
						</PopoverSurface>
					</Popover>
					<InteractionTagSecondary />
				</InteractionTag>
			);
		});
	}, [selectedLayers, maps, t, classes]);

	const positioning = useMemo<PositioningShorthand>(() => ({
		autoSize: true,
		overflowBoundary: layerListBoundaryRef,
	}), [layerListBoundaryRef]);

	const mapWmsOptions = useMemo(() => maps
			.filter(map => map.type !== 'Wmts')
			.map(map => ({
				value: map.id,
				children: <Text className={classes.layerOption}>{map.laag}</Text>,
			})),
		[maps, classes.layerOption],
	);

	const mapWmtsOptions = useMemo(() => maps
			.filter(map => map.type === 'Wmts')
			.map(map => <option key={map.id} value={map.id}>{map.laag}</option>),
		[maps],
	);

	const onChangeLayerQuery = (ev: React.ChangeEvent<HTMLInputElement>) => {
		setLayerQuery(ev.target.value);
	};

	const onChangeBackgroundLayerQuery = (ev: React.ChangeEvent<HTMLSelectElement>) => {
		setBackgroundLayerId(ev.target.value);
	};

	const layerOptions = useComboboxFilter(layerQuery, mapWmsOptions, {
		noOptionsMessage: t('mapsPage.noLayersFound'),
	});

	const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		if (data.optionValue === '') {
			setLayerQuery('');
		} else {
			setSelectedLayers(data.selectedOptions);
		}
	};

	const removeLayer: TagDismissHandler = (_, data) => {
		setSelectedLayers(layers => layers.filter(id => id !== data.value));
	};

	const onScaleChecked = useCallback((ev: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
		const [scale, zoomLevel] = ev.target.id.split('.').map(stringValue => +stringValue);
		if (ev.target.checked) {
			map.getView().setZoom(zoomLevel);
		}
		setSelectedScales(prev => {
			if (data.checked && !prev.includes(scale)) {
				return [...prev, scale];
			} else if (!data.checked && prev.includes(scale)) {
				return prev.filter(value => value !== scale);
			}
			return prev;
		});
	}, [map]);

	const onDownloadSeparatelyChecked = useCallback((_: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
		setMergeLayers(data.checked === true);
	}, []);

	const downloadFiles = useCallback(() => {
		const isMatchingId = (entry: GeoLocationResponseInterfaceEntry): boolean => entry.id === selectedAddressEntry?.id;
		const selectedAddress = addresses?.addressEntries.find(isMatchingId) ?? addresses?.projectEntries.find(isMatchingId);
		if (!selectedAddress) return;
		let url = `/api/geo-map/images?baseLayer=${backgroundLayerId}&separateByLayer=${!mergeLayers}&region=${region}`;
		selectedLayers.forEach((layer) => {
			url += `&mapNames=${layer}`;
		});
		selectedScales.forEach((scale) => {
			url += `&scales=${scale}`;
		});
		if (selectedAddressEntry) {
			url += `&address=${selectedAddress.coords.address}`;
		}
		setIsDownloadingFiles(true);
		api.get(url, { responseType: 'blob' }).then(blobResponse => {
			if (blobResponse.status >= 400) {
				(blobResponse.data as Blob).text().then(errorText => {
					dispatchToast(
						<Toast>
							<ToastTitle>{t('error')}</ToastTitle>
							<ToastBody>{errorText}</ToastBody>
						</Toast>,
						{ intent: 'error' },
					);
				});

				return;
			}
			const url = window.URL.createObjectURL(blobResponse.data);
			const a = document.createElement('a');
			a.href = url;
			a.download = blobResponse.headers['content-disposition']?.split('filename="')?.[1].split('";')?.[0] ?? 'image.zip';
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		}).catch(() => {
			dispatchToast(
				<Toast>
					<ToastTitle>{t('error')}</ToastTitle>
					<ToastBody>{t('genericErrorMessage')}</ToastBody>
				</Toast>,
				{ intent: 'error' },
			);
		}).finally(() => {
			setIsDownloadingFiles(false);
		});
	}, [selectedLayers, selectedScales, selectedAddressEntry, addresses, t, dispatchToast, mergeLayers, backgroundLayerId, region]);

	return (
		<div className={classes.root}>
			<Toaster toasterId={errorToastId} />
			<div className={classes.searchBarTop}>
				<div className={classes.flex1}>
					<AutofillSearchBar searchedSearchTerm={searchTerm} required onSearch={setSearchTerm} placeholder={t('typeToSearch')} label={t('mapsPage.searchAddressOrProject')}
											 tooltip={t('mapsPage.searchAddressOrProjectTooltip')}
											 onSelectEntry={setSelectedAddressEntry} selectedEntry={selectedAddressEntry} options={addressOptions} />
				</div>
				<div className={classes.backgroundPicker}>
					<Label>{t('mapsPage.backgroundLayer')}</Label>
					<Select onChange={onChangeBackgroundLayerQuery} value={backgroundLayerId}>
						{mapWmtsOptions}
					</Select>
				</div>
			</div>
			<div className={classes.row}>
				<div className={classes.leftColumn}>
					<div className={classes.mapContainer} id={'map'} ref={mapRef} />
					<div className={classes.scaleSelectionContainer}>
						<Text>{t('mapsPage.selectScales')}</Text>
						<div className={classes.row}>
							{scales.map(scale => <Checkbox
								key={scale.scale}
								label={t(`mapsPage.scale${scale.scale}`)}
								id={`${scale.scale}.${scale.zoomLevel}`}
								checked={selectedScales.includes(+scale.scale)}
								onChange={onScaleChecked}
							/>)}
						</div>
					</div>
				</div>
				<div className={classes.rightColumn} ref={setLayerListBoundaryRef}>
					<div className={classes.searchBarLayers}>
						<Label>{t('mapsPage.searchLayer')}</Label>
						<Combobox multiselect positioning={positioning} selectedOptions={selectedLayers} onOptionSelect={onOptionSelect} freeform
									 placeholder={t('typeToSearch')}
									 onChange={onChangeLayerQuery} value={layerQuery}>
							{layerOptions}
						</Combobox>
					</div>

					<TagGroup className={classes.selectedLayersContainer} onDismiss={removeLayer}>
						{tagElements}
					</TagGroup>
					<div className={classes.downloadButtonContainer}>
						<Checkbox label={t('mapsPage.mergeLayers')} onChange={onDownloadSeparatelyChecked} />
						<Button disabled={isDownloadingFiles || selectedScales.length === 0 || !selectedAddressEntry} onClick={downloadFiles} size={'large'}
								  appearance={'primary'}>
							{isDownloadingFiles ? <Spinner size={'tiny'} /> : <Text>Download</Text>}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MapsPage;