import React, { useMemo } from 'react';
import ChatHistoryDay from './ChatHistoryDay';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/useAppSelector';
import { ChatState } from '../redux/states/conversations/ChatState';

const sortByDate = (a: string, b: string): number => {
	return (new Date(b).getTime() - new Date(a).getTime());
};

const ChatHistory: React.FC = () => {
	const { t } = useTranslation();
	const { conversations } = useAppSelector(state => state.conversations);

	const sortedChatSessions = useMemo<Record<string, ChatState[]>>(() => {
		const dict: Record<string, ChatState[]> = {};
		Object.values(conversations).forEach((session) => {
			const date = new Date(session.createdOn).toDateString();
			if (!dict[date]) {
				dict[date] = [session];
			} else {
				dict[date] = [...dict[date], session].sort((a, b) => sortByDate(a.createdOn, b.createdOn));
			}
		});
		return dict;
	}, [conversations]);

	return (
		<div className={'flex flex-1 flex-col space-y-4'}>
			<div className={'flex flex-row justify-between items-center font-semibold text-xl'}>
				<p>{t('chatPage.history.title')}</p>
				<span className="material-symbols-outlined">history</span>
			</div>
			<div className={'flex flex-col flex-1 h-min  overflow-y-scroll'}>
				{Object.entries(sortedChatSessions).sort((a, b) => sortByDate(a[0], b[0])).map(([date, sessions], index) => <ChatHistoryDay
					date={date}
					chatSessions={sessions}
					key={date}
					showDivider={index !== Object.keys(sortedChatSessions).length - 1}
				/>)}
			</div>
		</div>
	);
};

export default ChatHistory;