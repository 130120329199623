import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import { ImageArcGISRest, ImageWMS, WMTS } from 'ol/source';
import { View } from 'ol';
import { getCenter } from 'ol/extent';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import MapLayerResponseInterface from '../interfaces/response/MapLayerResponseInterface';
import { Projection } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import axios from 'axios';
import { WMTSCapabilities } from 'ol/format';
import ImageLayer from 'ol/layer/Image';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import Region from '../interfaces/Region';


const lambertProjectionId = 'EPSG:31370';

proj4.defs(lambertProjectionId, '+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=51.1666672333333 +lat_2=49.8333339 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,-0.3366,0.457,-1.8422,-1.2747 +units=m +no_defs +type=crs');
register(proj4);

const projectionFlanders = new Projection({
	code: 'EPSG:31370',
	extent: [14697.3, 140000.01, 297193.15, 246456.18],
	worldExtent: [14637.25, 20909.21, 297133.13, 246424.28],
	units: 'm',
});
const projectionWallonia = new Projection({
	code: 'EPSG:31370',
	extent: [42000, 20000, 296000, 168000],
	worldExtent: [14637.25, 20909.21, 297133.13, 246424.28],
	units: 'm',
});

const newMap = new Map({
	controls: defaultControls().extend([new ScaleLine({
		units: 'metric',
	})]),
	layers: [],
});


const addWmtsToMap = async (mapLayerDefinition: MapLayerResponseInterface, map: Map, index: number) => {
	const existingLayer = map.getLayers().getArray().find(c => c.get('id') === mapLayerDefinition.id);
	if (existingLayer) {
		existingLayer.setZIndex(index);
		return;
	}
	const response = await axios.get(`${mapLayerDefinition.urlService}?request=GetCapabilities&version=1.3.0&service=${mapLayerDefinition.nameLayer}`);
	if (response.status !== 200) {
		return;
	}
	const parser = new WMTSCapabilities();
	const result = parser.read(response.data);
	const options = optionsFromCapabilities(result, {
		layer: mapLayerDefinition.nameLayer,
		projection: lambertProjectionId,
	});
	if (!options) {
		return;
	}
	map.getLayers().push(new TileLayer({
		opacity: mapLayerDefinition.transparency,
		className: mapLayerDefinition.id,
		visible: true,
		source: new WMTS({
			...options,
			style: mapLayerDefinition.stijl ?? '',
		}),
		properties: mapLayerDefinition,
		zIndex: index,
	}));
};

const addWmsToMap = async (mapLayerDefinition: MapLayerResponseInterface, map: Map, index: number) => {
	const existingLayer = map.getLayers().getArray().find(c => c.get('id') === mapLayerDefinition.id);
	if (existingLayer) {
		existingLayer.setZIndex(index);
		return;
	}
	if (mapLayerDefinition.type === 'Export') {
		map.addLayer(new ImageLayer({
			opacity: mapLayerDefinition.transparency,
			source: new ImageArcGISRest({
				url: mapLayerDefinition.urlService,
				ratio: 1,
				params: {
					LAYERS: mapLayerDefinition.nameLayer,
				},
			}),
			properties: mapLayerDefinition,
			zIndex: index,
		}));
	} else {
		map.addLayer(new ImageLayer({
			opacity: mapLayerDefinition.transparency,
			source: new ImageWMS({
				url: mapLayerDefinition.urlService,
				ratio: 1,
				params: {
					LAYERS: mapLayerDefinition.nameLayer,
					STYLES: mapLayerDefinition.stijl ?? '',
				},
			}),
			properties: mapLayerDefinition,
			zIndex: index,
		}));
	}

};

const useMap = (region: Region, backgroundMapId: string, target: HTMLDivElement | null, mapLayerDefinitions: MapLayerResponseInterface[], activeLayers: string[]) => {
	const defaultMapDefinition = useMemo(() => mapLayerDefinitions.find(c => c.id === backgroundMapId), [mapLayerDefinitions, backgroundMapId]);
	const map = useRef(newMap);
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		if (!target) return;
		map.current.setTarget(target);
		const projection = region === 'Flanders' ? projectionFlanders : projectionWallonia;
		map.current.setView(new View({
			projection: projection,
			extent: projection.getExtent(),
			center: getCenter(projection.getExtent()),
			zoom: 0,
			minZoom: 0,
			maxZoom: 15,
			constrainResolution: true,
		}));
		forceUpdate();
	}, [target, region]);

	useEffect(() => {
		if (!defaultMapDefinition) return;
		addWmtsToMap(defaultMapDefinition, map.current, 0);
		forceUpdate();
	}, [defaultMapDefinition]);

	useEffect(() => {
		activeLayers.forEach((layer, index) => {
			const mapDefinition = mapLayerDefinitions.find(c => c.id === layer);
			if (!mapDefinition || mapDefinition.type === 'Wmts') return;
			addWmsToMap(mapDefinition, map.current, index + 1);
		});
		map.current.getLayers().forEach(layer => {
			if (layer instanceof VectorLayer) return;
			const layerId = layer.get('id');
			layer.setVisible(activeLayers.includes(layerId) || backgroundMapId === layerId);
		});
		forceUpdate();
	}, [mapLayerDefinitions, activeLayers, backgroundMapId]);

	return map.current;
};


export default useMap;