import React from 'react';
import { Label, makeStyles, Select, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { api } from '../index';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { ActiveUserInfo } from '../redux/states/app/AppState';

const useClasses = makeStyles({
	root: {
		marginTop: tokens.spacingVerticalXXXL,
		marginBottom: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
	},
	adminContainer: {
		display: 'flex',
		flex: 1,
		wordBreak: 'break-all',
		flexDirection: 'column',
	},
	header: {
		fontSize: tokens.fontSizeBase600,
		fontWeight: 'bold',
		marginBottom: tokens.spacingVerticalM,
		marginTop: tokens.spacingVerticalM,
	},
});

const SettingsPage: React.FC = () => {
	const activeUserInfo = useSelector<RootState, ActiveUserInfo | undefined>(state => state.app.activeUserInfo);
	const classes = useClasses();
	const { t, i18n: { language, changeLanguage } } = useTranslation();

	const onLanguageSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		changeLanguage(event.target.value);
		(api.defaults.headers as any)['Accept-Language'] = event.target.value;
	};

	return (
		<div className={classes.root}>
			<div>
				<Label>{t('settingsPage.language')}</Label>
				<Select value={language} onChange={onLanguageSelect}>
					<option value={'en'}>{t('settingsPage.languageEN')}</option>
					<option value={'nl'}>{t('settingsPage.languageNL')}</option>
					<option value={'fr'}>{t('settingsPage.languageFR')}</option>
				</Select>
			</div>
			{activeUserInfo?.isAdmin && (
				<div className={classes.adminContainer}>
					<Text className={classes.header}>Access token</Text>
					<Text>{activeUserInfo?.accessToken}</Text>
				</div>
			)}
		</div>
	);
};

export default SettingsPage;