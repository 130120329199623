import { useMsal } from '@azure/msal-react';
import React from 'react';
import { scopes } from '../authConfig';
import { InteractionStatus } from '@azure/msal-browser';
import { Button, makeStyles, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { LockOpenRegular } from '@fluentui/react-icons';

const useClasses = makeStyles({
	root: {
		flex: 1,
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		display: 'flex',
	},
	header: {
		fontSize: tokens.fontSizeHero1000,
		marginTop: tokens.spacingVerticalXXXL,
	},
	headerContainer: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	flex1: {
		flex: 1,
		justifyContent: 'center',
		flexDirection: 'column',
		display: 'flex',
	},
	ueIcon: {
		width: '8em',
		height: '8em',
	},
});

const LoginPage: React.FC = () => {
	const { t } = useTranslation();
	const classes = useClasses();
	const { instance, inProgress } = useMsal();

	const logIn = () => {
		instance.loginRedirect({ scopes });
	};

	if (inProgress !== InteractionStatus.None) {
		return (
			<div className={classes.root}>
				<Spinner size={'huge'} />
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<img className={classes.ueIcon} src={'logo_purple.png'} alt={'UE logo'} />
				<Text className={classes.header}>{t('loginPage.headerTitle')}</Text>
			</div>
			<div className={classes.flex1}>
				<Button onClick={logIn} icon={<LockOpenRegular />} appearance={'primary'} size={'large'}>{t('loginPage.signIn')}</Button>
			</div>
			<div className={classes.flex1} />
		</div>
	);
};

export default LoginPage;
