import React, { useEffect, useMemo, useState } from 'react';
import TemplateDocumentConfigurationResponseInterface from '../interfaces/response/TemplateDocumentConfigurationResponseInterface';
import useGenerateDocumentMutation from '../hooks/mutations/useGenerateDocumentMutation';
import { DocumentType } from '../interfaces/response/DocumentInfo';
import SharepointDocumentTemplateConfigurationResponse from '../interfaces/response/SharepointDocumentTemplateConfigurationResponse';
import SharepointDocumentReference from '../interfaces/request/SharepointDocumentReference';
import NameAndId from '../interfaces/NameAndId';
import { Button, makeStyles, PositioningShorthand, ProgressBar, Text, tokens } from '@fluentui/react-components';
import Stepper from '../components/Stepper';
import DocumentGenerationSelectProjectStep from '../components/document-generation/DocumentGenerationSelectProjectStep';
import DocumentGenerationSelectDocumentOptions from '../components/document-generation/DocumentGenerationSelectDocumentOptions';
import DocumentGenerationSelectDocumentContent from '../components/document-generation/DocumentGenerationSelectDocumentContent';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselButton, CarouselCard, CarouselSlider } from '@fluentui/react-carousel-preview';
import GenerateDocumentDataInterface from '../interfaces/request/GenerateDocumentDataInterface';
import Region from '../interfaces/Region';

const useClasses = makeStyles({
	root: {
		marginTop: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
	},
	title: {
		fontSize: tokens.fontSizeBase500,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	generatingDocumentContainer: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		display: 'flex',
	},
	generatingDocumentSpinnerContainer: {
		width: '40em',
		flexDirection: 'column',
		alignItems: 'center',
		display: 'flex',
	},
	carouselContainer: {
		flexDirection: 'column',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		marginTop: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	carouselButtonsContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	carouselSlider: {
		minHeight: 0,
	},
	flex1: {
		flex: 1,
		display: 'flex',
	},
	newGenerationButton: {
		marginTop: tokens.spacingVerticalL,
	},
	column: {
		flexDirection: 'column',
		width: '20em',
		display: 'flex',
	},
	flex: {
		display: 'flex',
	},
});

const DocumentsPage: React.FC = () => {
	const { t } = useTranslation();
	const classes = useClasses();
	const generateDocumentMutation = useGenerateDocumentMutation();
	const [activeWizardStep, setActiveWizardStep] = useState<number>(0);
	const [selectedCompany, setSelectedCompany] = useState<NameAndId>();
	const [selectedProject, setSelectedProject] = useState<NameAndId>();
	const [selectedClient, setSelectedClient] = useState<NameAndId>();
	const [selectedContact, setSelectedContact] = useState<NameAndId>();
	const [selectedDocument, setSelectedDocument] = useState<TemplateDocumentConfigurationResponseInterface>();
	const [selectedRegion, setSelectedRegion] = useState<Region>('Flanders');
	const [requestData, setRequestData] = useState<GenerateDocumentDataInterface>({ RequestData: {} });
	const [templateTypes, setTemplateTypes] = useState<DocumentType[]>([]);
	const [autofillBoundaryRef, setAutofillBoundaryRef] = useState<HTMLDivElement | null>(null);

	const positioning = useMemo<PositioningShorthand>(() => ({
		autoSize: true,
		overflowBoundary: autofillBoundaryRef,
	}), [autofillBoundaryRef]);

	useEffect(() => {
		const newTypeList: DocumentType[] = [];
		if (selectedContact) {
			newTypeList.push('Contact');
		}
		if (selectedClient) {
			newTypeList.push('Client');
		}
		if (selectedProject) {
			newTypeList.push('Project');
		}
		if (newTypeList.join(',') === templateTypes.join(',')) return;
		setTemplateTypes(newTypeList);
		setSelectedDocument(undefined);
	}, [selectedContact, selectedClient, selectedProject, templateTypes]);

	const resetState = () => {
		setActiveWizardStep(0);
		setRequestData({ RequestData: {} });
		setSelectedProject(undefined);
		setSelectedClient(undefined);
		setSelectedContact(undefined);
		generateDocumentMutation.reset();
	};

	useEffect(() => {
		setRequestData(prev => {
			const copy = { ...prev };
			copy.RequestData.ProjectId = selectedProject?.id;
			return copy;
		});
	}, [selectedProject]);

	useEffect(() => {
		setRequestData(prev => {
			const copy = { ...prev };
			copy.RequestData.ContactId = selectedContact?.id;
			return copy;
		});
	}, [selectedContact]);

	useEffect(() => {
		setRequestData(prev => {
			const copy = { ...prev };
			copy.RequestData.ClientId = selectedClient?.id;
			return copy;
		});
	}, [selectedClient]);

	const isNextDisabled = () => {
		if (activeWizardStep === 0) {
			return templateTypes.length === 0;
		}
		if (activeWizardStep === 1) {
			return !selectedDocument;
		}
		return false;
	};

	const generateDocument = (content: SharepointDocumentTemplateConfigurationResponse[]) => {
		if (!selectedCompany || !selectedDocument) return;
		generateDocumentMutation.mutate({
			DocumentName: selectedDocument?.name,
			DocumentSections: content.map(item => ({
				DocumentId: item.sharepointDocumentId,
				DriveId: item.sharepointDriveId,
			} as SharepointDocumentReference)),
			ProjectId: selectedProject?.id,
			ContactId: selectedContact?.id,
			ClientId: selectedClient?.id,
			Data: requestData,
			Company: selectedCompany.id,
		});
	};

	const onCarouselIndexChange = (_: any, { index }: { index: number }) => {
		setActiveWizardStep(index);
	};

	const generationText = useMemo(() => {
		if (generateDocumentMutation.generatedDocumentResponse) {
			return generateDocumentMutation.isError ? t('documentsPage.failedGeneratingDocument') : t('documentsPage.generatedDocument');
		} else {
			return t('documentsPage.generatingDocument');
		}
	}, [generateDocumentMutation, t]);

	return (
		<div className={classes.root}>
			<Stepper currentStep={activeWizardStep} stepCount={3} />
			{generateDocumentMutation.isPending || !!generateDocumentMutation.generatedDocumentResponse ? (
				<div className={classes.generatingDocumentContainer}>
					<div className={classes.generatingDocumentSpinnerContainer}>
						<Text className={classes.title}>{generationText}</Text>
						{!generateDocumentMutation.generatedDocumentResponse && <ProgressBar thickness={'large'} />}
						{!!generateDocumentMutation.generatedDocumentResponse && (
							<div className={classes.column}>
								{generateDocumentMutation.isError ?
									<Text>{generateDocumentMutation.generatedDocumentResponse}</Text> :
									<Button as={'a'} href={generateDocumentMutation.generatedDocumentResponse} target={'_blank'} size={'large'}
											  appearance={'primary'}>{t('documentsPage.navigateToSharepoint')}</Button>
								}
								<Button className={classes.newGenerationButton} appearance={'secondary'} size={'medium'} onClick={resetState}>{t('documentsPage.newGeneration')}</Button>
							</div>
						)}
					</div>
				</div>
			) : (
				<Carousel groupSize={1} className={classes.carouselContainer} onActiveIndexChange={onCarouselIndexChange} activeIndex={activeWizardStep}>
					<CarouselSlider className={classes.carouselSlider}>
						<CarouselCard className={classes.flex}>
							<DocumentGenerationSelectProjectStep
								positioning={positioning}
								setSelectedContact={setSelectedContact}
								setSelectedRegion={setSelectedRegion}
								selectedContact={selectedContact}
								selectedProject={selectedProject}
								selectedClient={selectedClient}
								setSelectedProject={setSelectedProject}
								setSelectedClient={setSelectedClient}
								setSelectedCompany={setSelectedCompany}
							/>
						</CarouselCard>
						<CarouselCard className={classes.flex}>
							{selectedCompany && <DocumentGenerationSelectDocumentOptions
								region={selectedRegion}
								selectedDocument={selectedDocument}
								types={templateTypes}
								selectedProjectId={selectedProject?.id}
								selectedCompany={selectedCompany}
								setSelectedDocument={setSelectedDocument}
								selectedProjectName={selectedProject?.name}
								selectedContactName={selectedContact?.name}
								selectedClientName={selectedClient?.name}
								setRequestData={setRequestData}
							/>}
						</CarouselCard>
						<CarouselCard className={classes.flex}>
							{selectedCompany && selectedDocument && <DocumentGenerationSelectDocumentContent
								region={selectedRegion}
								generateDocument={generateDocument}
								selectedCompany={selectedCompany}
								selectedDocument={selectedDocument}
								requestData={requestData}
								selectedProjectName={selectedProject?.name}
								selectedContactName={selectedContact?.name}
								selectedClientName={selectedClient?.name}
							/>}
						</CarouselCard>
					</CarouselSlider>
					<div className={classes.flex1} ref={setAutofillBoundaryRef} />
					<div className={classes.carouselButtonsContainer}>
						<CarouselButton navType={'prev'} type={'button'} appearance={'primary'}>
							{t('documentsPage.previous')}
						</CarouselButton>
						<div className={classes.flex1} />
						{activeWizardStep < 2 && (
							<CarouselButton navType={'next'} type={'button'} iconPosition={'after'} appearance={'primary'} disabled={isNextDisabled()}>
								{t('documentsPage.next')}
							</CarouselButton>)
						}
					</div>
				</Carousel>
			)}
		</div>
	);
};

export default DocumentsPage;
