import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';

const emptyResult: OptionTemplateConfiguration[] = [];

const getDocumentApiOptionsList: QueryFunction<OptionTemplateConfiguration[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}

	let url = queryKey[0] as string;
	if (!!queryKey[1]) {
		url += `${url.includes('?') ? '&' : '?'}projectId=${queryKey[1]}`;
	}

	const response = await api.get<OptionTemplateConfiguration[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetDocumentApiOptionsList = (projectId?: string, url?: string) => {
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: [url, projectId, 'getDocumentApiOptionsList'],
		queryFn: getDocumentApiOptionsList,
		enabled: !!url,
		refetchOnWindowFocus: false,
	});

	return {
		results: data || emptyResult,
		isFetching,
		error,
		refetch,
	};
};

export default useGetDocumentApiOptionsList;
