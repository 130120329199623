import ChatMessage from './ChatMessage';
import React, { useMemo } from 'react';
import ChatMessageResponseInterface from '../interfaces/response/ChatMessageResponseInterface';

type Props = {
	chatId: string;
	chatMessages: ChatMessageResponseInterface[];
	onChangeMessageFeedback: (positive: boolean, messageId: string) => void;
}

const ChatMessageList: React.FC<Props> = ({ chatMessages, onChangeMessageFeedback }) => {
	const elements = useMemo(() => {
		return chatMessages.map((message, index, messages) => (
			<ChatMessage onChangeFeedback={onChangeMessageFeedback} hideFeedbackButtons={index === messages.length - 1} key={message.timestamp} message={message}
							 showBadge={index === messages.length - 1 || message.authorRole !== messages[index + 1].authorRole} />
		));
	}, [onChangeMessageFeedback, chatMessages]);
	
	return (
		<div className=" flex flex-1 mt-4 mb-4 p-2 rounded-sm flex-col-reverse overflow-y-scroll md:w-[40vw]">
			{elements}
		</div>
	);
};

export default ChatMessageList;