import React, { useEffect } from 'react';
import TemplateDocumentInfo from '../../interfaces/response/TemplateDocumentConfigurationResponseInterface';
import useGetDocumentInfoList from '../../hooks/queries/useGetDocumentInfoList';
import { DocumentType } from '../../interfaces/response/DocumentInfo';
import useGetDocumentSectionInfoList from '../../hooks/queries/useGetDocumentSectionInfoList';
import DocumentGenerationOptionsList from './DocumentGenerationSectionsList';
import { Dropdown, Label, makeStyles, Option, OptionOnSelectData, SelectionEvents, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';
import NameAndId from '../../interfaces/NameAndId';
import Region from '../../interfaces/Region';

type Props = {
	selectedCompany: NameAndId;
	types: DocumentType[];
	region: Region;
	selectedProjectName?: string;
	selectedContactName?: string;
	selectedClientName?: string;
	selectedProjectId?: string;
	selectedDocument?: TemplateDocumentInfo;
	setSelectedDocument: (value: TemplateDocumentInfo) => void;
	setRequestData: React.Dispatch<React.SetStateAction<GenerateDocumentDataInterface>>;
};

const useClasses = makeStyles({
	container: {
		gap: '1em',
		overflow: 'auto',
		flexDirection: 'column',
		paddingLeft: tokens.spacingHorizontalXXXL,
		paddingRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		minHeight: 0,
		marginBottom: tokens.spacingVerticalL,
		display: 'flex',
	},
	textContainer: {
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
		display: 'flex',
	},
	h1: {
		fontWeight: tokens.fontWeightSemibold,
		fontSize: tokens.fontSizeBase600,
	},
	h2: {
		fontSize: tokens.fontSizeBase400,
	},
	companyName: {
		fontWeight: tokens.fontWeightBold,
		fontSize: tokens.fontSizeHero800,
		marginBottom: tokens.spacingVerticalL,
		paddingTop: '1px',
	},
	column: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	flex: {
		display: 'flex',
	},
});

const DocumentGenerationSelectDocumentOptions: React.FC<Props> = ({
																							selectedCompany,
																							types,
																							region,
																							setSelectedDocument,
																							selectedProjectName,
																							selectedProjectId,
																							selectedContactName,
																							selectedClientName,
																							selectedDocument,
																							setRequestData,
																						}) => {
	const classes = useClasses();
	const { t } = useTranslation();
	const { results: documentsResult, isLoading: isLoadingDocuments } = useGetDocumentInfoList(types, region);
	const { results: sectionsResult } = useGetDocumentSectionInfoList(region, selectedDocument?.id);

	useEffect(() => {
		if (documentsResult.length === 0) return;
		setSelectedDocument(documentsResult[0]);
	}, [setSelectedDocument, documentsResult]);

	const onDocumentSelected = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedDocument(documentsResult.find(document => document.id === data.optionValue)!);
		setRequestData(prev => ({ RequestData: prev.RequestData }));
	};

	return (
		<div className={classes.container}>
			<div className={classes.textContainer}>
				<Text className={classes.companyName}>{selectedCompany.name}</Text>
				<Text className={classes.h1}>{selectedProjectName ? selectedProjectName : t('documentsPage.noProjectSelected')}</Text>
				{selectedClientName && <Text className={classes.h2}>{`${selectedClientName}${selectedContactName ? ` - ${selectedContactName}` : ''}`}</Text>}
			</div>
			{(isLoadingDocuments || (documentsResult.length > 0 && !selectedDocument)) ? (
				<div className={classes.flex}>
					<Spinner />
				</div>
			) : documentsResult.length === 0 ? <Text>{t('documentsPage.noTemplates')}</Text> : (
				<div className={classes.column}>
					<Label required>{t('documentsPage.documentToGenerate')}</Label>
					<Dropdown onOptionSelect={onDocumentSelected} defaultValue={selectedDocument!.name} defaultSelectedOptions={[selectedDocument!.id]}>
						{documentsResult.map((document) => (
							<Option key={document.id} value={document.id}>
								{document.name}
							</Option>
						))}
					</Dropdown>
				</div>
			)}
			<DocumentGenerationOptionsList selectedDocumentId={selectedDocument?.id} projectId={selectedProjectId} setRequestData={setRequestData} sections={sectionsResult} />
		</div>
	);
};

export default DocumentGenerationSelectDocumentOptions;
