import React, { useState } from 'react';
import { makeStyles, mergeClasses, tokens, Tooltip, TooltipProps } from '@fluentui/react-components';
import { Info16Regular } from '@fluentui/react-icons';

type Props = {
	text: string;
} & Partial<TooltipProps>;

const useClasses = makeStyles({
	highlightedIcon: {
		color: tokens.colorNeutralForeground2BrandSelected,
	},
});

const TooltipIcon: React.FC<Props> = (props) => {
	const classes = useClasses();
	const [visible, setVisible] = useState<boolean>(false);

	return (
		<Tooltip
			content={props.text}
			positioning="above-start"
			withArrow
			relationship="label"
			onVisibleChange={(_, data) => setVisible(data.visible)}
			{...props}
		>
			<Info16Regular
				tabIndex={0}
				className={mergeClasses(visible && classes.highlightedIcon)}
			/>
		</Tooltip>
	);
};

export default TooltipIcon;