import React, { useEffect, useMemo, useState } from 'react';
import useGetDocumentSectionInfoList from '../../hooks/queries/useGetDocumentSectionInfoList';
import SharepointDocumentTemplateConfigurationResponse from '../../interfaces/response/SharepointDocumentTemplateConfigurationResponse';
import TemplateDocumentConfigurationResponseInterface from '../../interfaces/response/TemplateDocumentConfigurationResponseInterface';
import { Button, Checkbox, Divider, Label, makeStyles, shorthands, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';
import Region from '../../interfaces/Region';

type Props = {
	selectedDocument: TemplateDocumentConfigurationResponseInterface;
	selectedCompany: TemplateDocumentConfigurationResponseInterface;
	selectedProjectName?: string;
	selectedContactName?: string;
	selectedClientName?: string;
	requestData: GenerateDocumentDataInterface;
	generateDocument: (content: SharepointDocumentTemplateConfigurationResponse[]) => void;
	region: Region;
};


const useClasses = makeStyles({
	container: {
		gap: '1em',
		overflow: 'auto',
		flexDirection: 'column',
		paddingLeft: tokens.spacingHorizontalXXXL,
		paddingRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		minHeight: 0,
		marginBottom: tokens.spacingVerticalL,
		display: 'flex',
	},
	textContainer: {
		flexDirection: 'column',
		gap: tokens.spacingVerticalL,
		display: 'flex',
	},
	h1: {
		fontWeight: tokens.fontWeightSemibold,
		fontSize: tokens.fontSizeBase600,
	},
	h2: {
		fontSize: tokens.fontSizeBase400,
	},
	companyName: {
		fontWeight: tokens.fontWeightBold,
		fontSize: tokens.fontSizeHero800,
		marginBottom: tokens.spacingVerticalL,
	},
	column: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	documentContentContainer: {
		userSelect: 'none',
		flexDirection: 'column',
		borderTopColor: tokens.colorBrandBackgroundStatic,
		borderBottomColor: tokens.colorBrandBackgroundStatic,
		borderLeftColor: tokens.colorBrandBackgroundStatic,
		borderRightColor: tokens.colorBrandBackgroundStatic,
		borderRadius: tokens.borderRadiusSmall,
		...shorthands.borderWidth('1px'),
		overflow: 'scroll',
		flex: 1,
		display: 'flex',
	},
	label: {
		fontSize: tokens.fontSizeBase400,
	},
	flex: {
		display: 'flex',
	},
});


const DocumentGenerationSelectDocumentContent: React.FC<Props> = ({
																							selectedCompany,
																							selectedDocument,
																							selectedProjectName,
																							selectedContactName,
																							selectedClientName,
																							generateDocument,
																							requestData,
																							region,
																						}) => {
	const classes = useClasses();
	const { t } = useTranslation();
	const { results: sectionInfoResult, isLoading } = useGetDocumentSectionInfoList(region, selectedDocument.id);
	const sharepointDocuments = useMemo<SharepointDocumentTemplateConfigurationResponse[]>(() => {
		return sectionInfoResult.filter(info => info.type === 'SharepointDocument').map(info => info as SharepointDocumentTemplateConfigurationResponse);
	}, [sectionInfoResult]);
	const [selectedContent, setSelectedContent] = useState<SharepointDocumentTemplateConfigurationResponse[]>([]);

	useEffect(() => {
		setSelectedContent(sharepointDocuments.filter(info => {
			const preselected = info.isPreselected;
			const optionEntries = Object.entries(info.requiredOptions);
			const hasSelectedOption = optionEntries.length > 0 && optionEntries.every(([section, ids]) => !!ids.find(id => requestData[section] && id in requestData[section]));
			return preselected || hasSelectedOption;
		}));
	}, [requestData, sharepointDocuments]);

	const generateDocumentClicked = () => {
		generateDocument(selectedContent.sort((a, b) => sharepointDocuments.indexOf(a) - sharepointDocuments.indexOf(b)));
	};

	return (
		<div className={classes.container}>
			<div className={classes.textContainer}>
				<Text className={classes.companyName}>{selectedCompany.name} - {selectedDocument.name}</Text>
				<Text className={classes.h1}>{selectedProjectName ? selectedProjectName : t('documentsPage.noProjectSelected')}</Text>
				{selectedClientName && <Text className={classes.h2}>{`${selectedClientName}${selectedContactName ? ` - ${selectedContactName}` : ''}`}</Text>}
			</div>
			{isLoading ? (
				<div className={classes.flex}>
					<Spinner />
				</div>
			) : (
				<>
					<Label className={classes.label}>{t('documentsPage.selectDocuments')}</Label>
					<div className={classes.documentContentContainer}>
						{sharepointDocuments.map((content, index) => {
							const isChecked = selectedContent.includes(content);
							const onClickCheckbox: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
								const active = evt.target.checked;
								if (active) {
									setSelectedContent((prev) => [...prev, content]);
								} else {
									setSelectedContent((prev) => prev.filter((i) => i !== content));
								}
							};
							return (
								<div className={classes.column} key={content.id}>
									<Checkbox label={content.name} onChange={onClickCheckbox} checked={isChecked} />
									{index < sharepointDocuments.length - 1 && <Divider />}
								</div>
							);
						})}
					</div>
				</>
			)}
			<div className={classes.flex}>
				<Button onClick={generateDocumentClicked} disabled={isLoading} appearance={'primary'} size={'large'}>{t('documentsPage.generateDocument')}</Button>
			</div>
		</div>
	);
};

export default DocumentGenerationSelectDocumentContent;
