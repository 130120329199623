import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import React from 'react';
import DocumentGenerationOption from './DocumentGenerationOption';
import { makeStyles, Text, tokens } from '@fluentui/react-components';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';

type Props = {
	selectedDocumentId?: string;
	section: OptionSectionTemplateConfigurationResponse;
	setRequestData: React.Dispatch<React.SetStateAction<GenerateDocumentDataInterface>>;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		display: 'flex',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
	column: {
		flexDirection: 'column',
		display: 'flex',
	},
});

const DocumentGenerationOptionSection: React.FC<Props> = ({ selectedDocumentId, section, setRequestData }) => {
	const classes = useClasses();
	return (
		<div className={classes.container}>
			<Text className={classes.sectionName}>{section.name}</Text>
			<div className={classes.column}>
				{section.options.map(option => <DocumentGenerationOption
					selectedDocumentId={selectedDocumentId}
					section={section.id}
					key={option.id + selectedDocumentId}
					setRequestData={setRequestData}
					option={option} />,
				)}
			</div>
		</div>
	);
};

export default DocumentGenerationOptionSection;