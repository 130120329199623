import React, { useCallback, useEffect, useState } from 'react';
import NameAndId from '../interfaces/NameAndId';
import { Combobox, ComboboxOpenChangeData, ComboboxOpenEvents, Label, makeStyles, Option, OptionOnSelectData, PositioningShorthand, SelectionEvents, tokens } from '@fluentui/react-components';
import TooltipIcon from './TooltipIcon';

type Props = {
	label: string;
	placeholder: string;
	options: NameAndId[];
	onSearch: (searchTerm: string) => void;
	onSelectEntry: (entry: NameAndId) => void;
	selectedEntry?: NameAndId;
	required?: boolean;
	tooltip?: string;
	searchedSearchTerm: string;
	positioning?: PositioningShorthand
};

const searchDelay: number = 200;

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	searchBarLabel: {
		marginRight: tokens.spacingHorizontalS,
	},
});

const AutofillSearchBar: React.FC<Props> = ({ placeholder, required, options, onSearch, label, onSelectEntry, selectedEntry, tooltip, searchedSearchTerm, positioning }) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const classes = useClasses();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setSearchTerm(prev => {
			if (prev === searchedSearchTerm || searchedSearchTerm === '*') return prev;
			return searchedSearchTerm;
		});
	}, [searchedSearchTerm]);

	useEffect(() => {
		if (!selectedEntry) {
			setSearchTerm('');
		}
	}, [selectedEntry]);

	useEffect(() => {
		if (searchTerm === selectedEntry?.name) return;
		const timeout = setTimeout(() => {
			onSearch?.(searchTerm);
		}, searchDelay);
		return () => clearTimeout(timeout);
	}, [searchTerm, selectedEntry, onSearch]);

	const onSearchChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(event => {
		setSearchTerm(event.target.value);
	}, []);

	const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		onSelectEntry(options.find(option => option.id === data.optionValue)!);
		if (data.optionText) {
			setSearchTerm(data.optionText);
		}
	};

	const onOpenChange = (_: ComboboxOpenEvents, data: ComboboxOpenChangeData) => {
		setIsOpen(data.open);
	};

	return (
		<div className={classes.container}>
			<div className={classes.row}>
				<Label className={classes.searchBarLabel} required={required}>{label}</Label>
				{tooltip && <TooltipIcon text={tooltip} />}
			</div>
			<Combobox positioning={positioning} open={options.length > 0 && isOpen} onOpenChange={onOpenChange} clearable freeform onOptionSelect={onOptionSelect} placeholder={placeholder}
						 value={searchTerm} 
						 onChange={onSearchChange}>
				{options.map((option) => (
					<Option key={option.id} value={option.id}>
						{option.name}
					</Option>
				))}
			</Combobox>
		</div>
	);
};

export default AutofillSearchBar;
