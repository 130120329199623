import React from 'react';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import useGetDocumentApiOptionsList from '../../hooks/queries/useGetDocumentApiOptionsList';
import DocumentGenerationOption from './DocumentGenerationOption';
import { Button, makeStyles, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';

type Props = {
	projectId?: string;
	selectedDocumentId?: string;
	section: OptionSectionApiTemplateConfigurationResponse;
	setRequestData: React.Dispatch<React.SetStateAction<GenerateDocumentDataInterface>>;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
	emptyResultContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: tokens.spacingHorizontalL,
	},
});

const DocumentGenerationOptionSectionApi: React.FC<Props> = ({ selectedDocumentId, projectId, section, setRequestData }) => {
	const options = useGetDocumentApiOptionsList(projectId, section.endpoint);
	const classes = useClasses();
	const { t } = useTranslation();

	const refreshData = () => {
		options.refetch();
	};

	return (
		<div className={classes.container}>
			<Text className={classes.sectionName}>{section.name}</Text>
			{options.isFetching ? <Spinner /> : options.results.length > 0 ? options.results.map(option => {
				return <DocumentGenerationOption selectedDocumentId={selectedDocumentId} setRequestData={setRequestData} section={section.id} key={option.id + selectedDocumentId}
															option={option} />;
			}) : (
				<div className={classes.emptyResultContainer}>
					<Text>{t('documentsPage.nothingFound')}</Text>
					<Button onClick={refreshData}>{t('refresh')}</Button>
				</div>
			)}
		</div>
	);
};

export default DocumentGenerationOptionSectionApi;