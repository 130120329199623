import ReactDOM from 'react-dom/client';
import './styling/index.css';
import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import ChatPage from './pages/ChatPage';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import DocumentsPage from './pages/DocumentsPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './i18n/en.json';
import nlTranslations from './i18n/nl.json';
import frTranslations from './i18n/fr.json';
import { Axios } from 'axios';
import MapsPage from './pages/MapsPage';
import React from 'react';
import SettingsPage from './pages/SettingsPage';

const api = new Axios({
	baseURL: process.env.REACT_APP_API_BASE_URL?.trim(),
	headers: {
		'Accept-Language': 'en',
	},
});
api.interceptors.response.use(
	function(response) {
		try {
			if (typeof response.data === 'string') {
				response.data = JSON.parse(response.data);
			}
			return response;
		} catch {
			return response;
		}
	},
	function(error) {
		return Promise.reject(error);
	},
);

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
	const isLoginEvent = event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS;
	if (!isLoginEvent || !event.payload) {
		return;
	}
	msalInstance.setActiveAccount(event.payload as AccountInfo);
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


i18n
	.use(initReactI18next)
	.init({
		resources: {
			en: { translation: enTranslations },
			nl: { translation: nlTranslations },
			fr: { translation: frTranslations },
		},
		lng: 'en',
		interpolation: {
			escapeValue: false,
		},
		parseMissingKeyHandler: (key) => {
			return '[UNTRANSLATED] ' + key;
		},
	});

const routes: RouteObject[] = [
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: 'chat',
				element: <ChatPage />,
			},
			{
				path: 'documents',
				element: <DocumentsPage />,
			},
			{
				path: 'settings',
				element: <SettingsPage />,
			},
			{
				path: 'maps',
				children: [
					{
						path: 'flanders',
						element: <MapsPage region={'Flanders'} />,
					},
					{
						path: 'wallonia',
						element: <MapsPage region={'Wallonia'} />,
					},
				],
			},
		],
		errorElement: <App />,
	},
];

const router = createBrowserRouter(routes);
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

root.render(
	<MsalProvider instance={msalInstance}>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
		</QueryClientProvider>
	</MsalProvider>,
);

export { routes, api };
