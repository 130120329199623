import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import React, { useState } from 'react';
import ChatContext, { ChatContextType } from './context/ChatContext';
import { Provider } from 'react-redux';
import store from './redux/store';
import { FluentProvider, makeStyles, tokens } from '@fluentui/react-components';
import { lightTheme } from './styling/theme';

const useClasses = makeStyles({
	root: {
		backgroundColor: tokens.colorNeutralBackground1,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		position: 'relative',
		flexDirection: 'column',
		display: 'flex',
	},
	flex: {
		display: 'flex',
	},
});

const App: React.FC = () => {
	const classes = useClasses();
	const chatContext = useState<ChatContextType>({});
	const isAuthenticated = useIsAuthenticated();

	return (
		<FluentProvider theme={lightTheme} className={classes.flex}>
			<ChatContext.Provider value={chatContext}>
				<div className={classes.root}>
					{isAuthenticated ? (
						<AuthenticatedTemplate>
							<Provider store={store}>
								<MainPage />
							</Provider>
						</AuthenticatedTemplate>
					) : (
						<LoginPage />
					)}
				</div>
			</ChatContext.Provider>
		</FluentProvider>
	);
};

export default App;
