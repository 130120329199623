import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import MapLayerResponseInterface from '../../interfaces/response/MapLayerResponseInterface';

const emptyResult: MapLayerResponseInterface[] = [];

const getMapLayers: QueryFunction<MapLayerResponseInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<MapLayerResponseInterface[]>(`/api/geo-map/layers?region=${queryKey[0]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetMapLayers = (region: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [region, 'getMapLayers'],
		queryFn: getMapLayers,
	});

	return {
		maps: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetMapLayers;