import React from 'react';
import TemplateConfigurationResponse from '../../interfaces/response/TemplateConfigurationResponse';
import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import DocumentGenerationOptionSection from './DocumentGenerationOptionSection';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import DocumentGenerationOptionSectionApi from './DocumentGenerationOptionSectionApi';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';

type Props = {
	selectedDocumentId?: string;
	projectId?: string;
	sections: TemplateConfigurationResponse[];
	setRequestData: React.Dispatch<React.SetStateAction<GenerateDocumentDataInterface>>;
}

const DocumentGenerationSectionsList: React.FC<Props> = ({ selectedDocumentId, projectId, sections, setRequestData }) => {
	return (
		<>
			{sections.map((section, index) => {
				if (section.type === 'OptionSection') {
					return <DocumentGenerationOptionSection key={index}
																		 selectedDocumentId={selectedDocumentId}
																		 section={section as OptionSectionTemplateConfigurationResponse}
																		 setRequestData={setRequestData} />;
				} else if (section.type === 'OptionSectionApi') {
					return <DocumentGenerationOptionSectionApi key={index}
																			 selectedDocumentId={selectedDocumentId}
																			 projectId={projectId}
																			 setRequestData={setRequestData}
																			 section={section as OptionSectionApiTemplateConfigurationResponse} />;
				}
				return null;
			})}
		</>
	);
};

export default DocumentGenerationSectionsList;